:root{
  
    --color-text: #2a2b33;
    --color-background: lightgray;
    --primary: #ff2d2d;
       
}

html, body{
  height: 100vh;
  overflow: hidden
}

#root{
  min-height: 100%;
}

.app{
  min-height: 100%;
}
body {
  margin: 0;
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
